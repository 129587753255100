import React, { useEffect, useState } from 'react';
import * as urlParse from 'url-parse';
import { notification, Row, Col, Skeleton } from 'antd';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { default as createApiGateway } from '../../../apiGateway';
import { validateDsp } from '../dsps/utils/validations';
import { authorizedPOST, authorizedPUT, authorizedDELETE } from '../../../helpers/requester';
import { dsp, list, user } from '../../../constants/endpoints';
import { Search, WhiteOrBlackList } from '../../../components';

import alpha3CountryCodeList from '../../../constants/alpha3CountryCodeList.json';
import creativeSizesList from '../../../constants/creativeSizesList.json';
import osList from '../../../constants/osList.json';

import UpsertDspForm from '../../../sections/dsps/UpsertDspForm';

const defaultDSPState = {
  partnerId: '',
  name: '',
  host: '',
  port: 80,
  path: '',
  useBanner: false,
  useNative: false,
  useVideo: false,
  usePop: false,
  callNurls: false,
  mobileWeb: false,
  inApp: false,
  desktop: false,
  videoStartTracker: false,
  instlOnly: 0,
  minTmax: 0,
  reqUserId: 0,
  useAdapter: 0,
  dailySpendLimit: 0,
  totalSpendLimit: 0,
  maxBidfloor: 0,
  onlyStoredApps: 0,
  onlyMatchedUsers: 0,
  pornFilter: 0,
  mismatchIpFilter: 0,
  mismatchBundlesFilter: 0,
  lowSecondPrice: 0,
  adapterLearningPercent: 0,
  PMB: 0,
  PMW: 0,
  FB: 0,
  FW: 0,
  secure: false,
  margin: 0,
  limitQps: 0,
  isActive: false,
  isBlocked: false,
  includeExtraMargin: false,
  extraMargin: 0,
  note: '',
  custom: null,
  useGzip: false,
  dnsResolve: false,
  optimizer: true,
};

const parseUrl = (url) => {
  const parsedUrl = urlParse(url, true);
  let host = parsedUrl.host || '';

  if (parsedUrl.port) {
    host = host.replace(`:${parsedUrl.port}`, '');
  }

  return {
    port: parseInt(parsedUrl.port, 10) || 80,
    host: `${parsedUrl.protocol}//${host}`,
    path:
      parsedUrl.pathname +
      (_.size(parsedUrl.query)
        ? `?${_.toPairs(parsedUrl.query)
            .map(([k, v]) => `${k}=${v}`)
            .join('&')}`
        : ''),
  };
};

export default function DspPage() {
  const params = useParams();

  const dspId = params.id;
  const isCreateMode = dspId === 'new';

  const apiGateway = createApiGateway();

  const userInfo = useSelector((state) => state.login);

  const [loading, setLoading] = useState(true);

  const [existingDsp, setExistingDsp] = useState();
  const [state, setState] = useState({});
  const [createDSPState, createDSPSetState] = useState(defaultDSPState);
  const [whiteLists, setWhiteLists] = useState({});

  const onEnd = () => {
    setTimeout(() => {
      window.location = `/dsps/${dspId}`;
    }, 1000 * 2);
  };

  useEffect(() => {
    (async () => {
      if (isCreateMode) {
        setLoading(false);

        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const dsp = await apiGateway.dsps.getById(dspId);

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const [user, countriesWhiteList, dspSspWhiteList, dspSizesWhiteList, dspOsWhiteList, ssps] = await Promise.all([
        apiGateway.users.getUserById(dsp.partnerId),
        apiGateway.dsps.getDspCountriesWhiteList(dsp.id),
        apiGateway.dsps.getDspSspWhiteList(dsp.id),
        apiGateway.dsps.getDspSizesWhiteList(dsp.id),
        apiGateway.dsps.getDspOsWhiteList(dsp.id),
        apiGateway.ssps.getAll(),
      ]);

      setState({
        ...state,
        selectedCompany: user,
        ssps,
      });

      setExistingDsp(dsp);
      createDSPSetState(dsp);

      setWhiteLists({
        ...whiteLists,
        countriesWhiteList,
        dspSspWhiteList,
        dspSizesWhiteList,
        dspOsWhiteList,
      });

      setLoading(false);
    })();
  }, []);

  const onCompanySelect = (data) => {
    createDSPSetState({
      ...createDSPState,
      partnerId: data.id,
    });

    setState({
      ...state,
      selectedCompany: data,
    });
  };

  const createDSP = async (values) => {
    try {
      let body = { ...values, partnerId: createDSPState.partnerId };

      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          if (body[key] === false) {
            body[key] = 0;
          }

          if (body[key] === true) {
            body[key] = 1;
          }
        }
      }

      if (body && body.endpointUrl) {
        body = _.omit(
          {
            ...body,
            ...parseUrl(body.endpointUrl),
          },
          'endpointUrl'
        );
      }

      body.custom = JSON.stringify(values.custom);

      validateDsp(body);

      await authorizedPOST(dsp.list, body);

      createDSPSetState(defaultDSPState);

      notification.success({ message: 'DSP created' });

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onEnd && onEnd();
    } catch (e) {
      notification.error({ message: `Error when creating a DSP: ${e}` });
    }
  };

  const updateDSP = async (values) => {
    if (!values.id) {
      notification.info({ message: 'First of all you need to fetch DSP using "Fetch DSP by ID" section' });
      return;
    }

    try {
      let body = { ...values };

      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          if (body[key] === false) {
            body[key] = 0;
          }

          if (body[key] === true) {
            body[key] = 1;
          }
        }
      }

      if (body && body.endpointUrl) {
        body = _.omit(
          {
            ...body,
            ...parseUrl(body.endpointUrl),
          },
          'endpointUrl'
        );
      }

      validateDsp(body);

      await authorizedPUT(dsp.list.concat('/', createDSPState.id), body);

      createDSPSetState(defaultDSPState);

      notification.success({ message: 'DSP updated' });
    } catch (e) {
      notification.error({ message: `Error when updating a DSP: ${e}` });
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onEnd && onEnd();
    }
  };

  const onDelete = (baseUrl) => async (e, data) => {
    try {
      await authorizedDELETE(baseUrl.replace(':id', data.id));
      notification.success({ message: 'Deleted' });
    } catch (error) {
      notification.error({ message: `Oops.. sth went wrong: ${error}` });
    }
  };

  const dataFilterFunc = (stateInput, data) =>
    (data || []).filter((x) => {
      if (!x || !x.firstName) return false;
      return x.firstName.toLowerCase().includes(stateInput.toLowerCase());
    });

  const allowedWhiteListCountryValues = alpha3CountryCodeList
    .map((x) => ({
      country: x.alpha3,
      name: x.country,
    }))
    .map((x) => {
      const sameFromApi = (whiteLists.countriesWhiteList || []).find((y) => y.country === x.country);

      return {
        __toggle: Boolean(sameFromApi),
        ...x,
        id: (sameFromApi && sameFromApi.id) || null,
      };
    });

  const allowedWhiteListSspValues = (state.ssps || [])
    .map((x) => ({
      sspId: x.id,
      name: x.name,
      active: x.isActive ? '+' : '-',
    }))
    .map((x) => {
      const sameFromApi = (whiteLists.dspSspWhiteList || []).find((y) => y.sspId === x.sspId);

      return {
        __toggle: Boolean(sameFromApi),
        ...x,
        id: (sameFromApi && sameFromApi.id) || null,
      };
    });

  const allowedWhiteListSizesValues = creativeSizesList
    .map((x) => ({ size: x }))
    .map((x) => {
      const sameFromApi = (whiteLists.dspSizesWhiteList || []).find((y) => y.size === x.size);

      return {
        __toggle: Boolean(sameFromApi),
        ...x,
        id: (sameFromApi && sameFromApi.id) || null,
      };
    });

  const allowedWhiteListOsValues = osList
    .map((x) => ({ os: x }))
    .map((x) => {
      const sameFromApi = (whiteLists.dspOsWhiteList || []).find((y) => y.os === x.os);

      return {
        __toggle: Boolean(sameFromApi),
        ...x,
        id: (sameFromApi && sameFromApi.id) || null,
      };
    });

  if (loading) {
    return (
      <Row>
        <Col offset={2} span={20}>
          <Skeleton active />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col offset={2} span={20}>
        {state.selectedCompany && createDSPState.partnerId ? (
          <div>
            Selected
            <b>{state.selectedCompany.firstName}</b> company with id
            <b>{state.selectedCompany.id}</b> (<b>{state.selectedCompany.exchangeName}</b>)
          </div>
        ) : (
          <Search
            title="Select Company"
            placeholder="Enter company name"
            baseUrl={user.root}
            onSelect={onCompanySelect}
            dataFilterFunc={dataFilterFunc}
          />
        )}
        {existingDsp && (
          <div>
            <b>StatUrl:</b>{' '}
            <a href={existingDsp.statUrl} target="_blank" rel="noreferrer">
              {existingDsp.statUrl}
            </a>
          </div>
        )}

        <br />

        <UpsertDspForm dsp={existingDsp} userInfo={userInfo} onSubmit={createDSPState.id ? updateDSP : createDSP} />

        {createDSPState.id && (
          <div className="col-md-12">
            <WhiteOrBlackList
              title="Add SSP to WL"
              urlTemplate={list.fetchWlSsps}
              id={createDSPState.id}
              primaryKeyName="dspId"
              keyName="sspId"
              parseValFunc={parseInt}
              postUrl={list.wlSspsBulk}
              onDelete={onDelete(list.deleteWlSsp)}
              allowedValues={allowedWhiteListSspValues}
              hiddenColumns={['id']}
            />
            <hr />

            <WhiteOrBlackList
              title="Add sizes to WL"
              urlTemplate={list.fetchWlSizes}
              id={createDSPState.id}
              primaryKeyName="dspId"
              keyName="size"
              postUrl={list.wlSizesBulk}
              helpTxt="format: 320x320, 180x240"
              onDelete={onDelete(list.deleteWlSizes)}
              allowedValues={allowedWhiteListSizesValues}
              hiddenColumns={['id']}
            />
            <hr />

            <WhiteOrBlackList
              title="Add OS to WL"
              urlTemplate={list.fetchWlOs}
              id={createDSPState.id}
              primaryKeyName="dspId"
              keyName="os"
              postUrl={list.wlOsBulk}
              onDelete={onDelete(list.deleteWlOs)}
              allowedValues={allowedWhiteListOsValues}
              hiddenColumns={['id']}
            />
            <hr />

            <WhiteOrBlackList
              title="Add Countries to WL"
              urlTemplate={list.fetchWlCountries}
              id={createDSPState.id}
              primaryKeyName="dspId"
              keyName="country"
              postUrl={list.wlCountriesBulk}
              onDelete={onDelete(list.deleteWlCountries)}
              allowedValues={allowedWhiteListCountryValues}
              hiddenColumns={['id']}
            />
            <hr />

            <WhiteOrBlackList
              title="Add Bundles to WL"
              urlTemplate={list.fetchWlBundles}
              id={createDSPState.id}
              primaryKeyName="dspId"
              keyName="bundle"
              postUrl={list.wlBundlesBulk}
              onDelete={onDelete(list.deleteWlBundles)}
              listName="whiteListDspBundles"
            />
            <hr />

            <WhiteOrBlackList
              title="Add Domains to WL"
              urlTemplate={list.fetchWlDomains}
              id={createDSPState.id}
              primaryKeyName="dspId"
              keyName="domain"
              postUrl={list.wlDomainsBulk}
              onDelete={onDelete(list.deleteWlDomains)}
              listName="whiteListDspDomains"
            />
            <hr />

            <WhiteOrBlackList
              title="Add IPs to BL"
              urlTemplate={list.fetchBlIPs}
              id={createDSPState.id}
              primaryKeyName="dspId"
              keyName="ip"
              postUrl={list.blIPsBulk}
              onDelete={onDelete(list.deleteBlIPs)}
              listName="blackListDspIPs"
            />
            <hr />

            <WhiteOrBlackList
              title="Add Bundles to BL"
              urlTemplate={list.fetchBlBundles}
              id={createDSPState.id}
              primaryKeyName="dspId"
              keyName="bundle"
              postUrl={list.blBundlesBulk}
              onDelete={onDelete(list.deleteBlBundles)}
              listName="blackListDspBundles"
            />
            <hr />

            <WhiteOrBlackList
              title="Add Domains to BL"
              urlTemplate={list.fetchBlDomains}
              id={createDSPState.id}
              primaryKeyName="dspId"
              keyName="domain"
              postUrl={list.blDomainsBulk}
              onDelete={onDelete(list.deleteBlDomains)}
              listName="blackListDspDomains"
            />
            <hr />

            <WhiteOrBlackList
              title="Add Publishers to BL"
              urlTemplate={list.fetchBlPublishers}
              id={createDSPState.id}
              primaryKeyName="dspId"
              keyName="pubId"
              postUrl={list.blPublishersBulk}
              onDelete={onDelete(list.deleteBlPublishers)}
              listName="blackListDspPublishers"
            />
          </div>
        )}
      </Col>
    </Row>
  );
}
