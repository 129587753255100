import querystring from 'querystring';

type Params = {
  authorizedGET: <T>(url: string) => Promise<T>;
};

type Endpoints = {
  statistics: {
    tables: string;
    auctionStatisticsByTable: (table: string) => string;
  };
};

export enum FieldType {
  ATTRIBUTE = 'ATTRIBUTE',
  DIMENSION = 'DIMENSION',
}

export type TableField = { key: string; name: string; type: FieldType; virtual?: boolean };

export type Table = {
  key: string;
  name: string;
  fields: TableField[];
};

export type ListTablesResponse = Table[];

export type ListStatisticsByTableResponse = {
  count: number;
  rows: object[];
};

export type SortOrder = 'ASC' | 'DESC';

type ListStatisticsParams = {
  table: string;
  from: string;
  to: string;
  dimensions: string[];
  limit?: number;
  offset?: number;
  orderBy?: string;
  orderDirection?: SortOrder;
  sspId?: number;
  dspId?: number;
  companyId?: number;
};

export function createAuctionStatistics({ authorizedGET }: Params, endpoints: Endpoints) {
  const listTables = () => authorizedGET<ListTablesResponse>(endpoints.statistics.tables);

  const getListStatisticsByTableUrl = (params: ListStatisticsParams & { export?: boolean }): string => {
    const queryParams = {
      from: params.from,
      to: params.to,
      limit: params.limit,
      offset: params.offset,
      dimensions: params.dimensions.join(','),
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      sspId: params.sspId,
      dspId: params.dspId,
      companyId: params.companyId,
    };

    if (!params.orderBy) {
      delete queryParams.orderBy;
    }

    if (!params.orderDirection) {
      delete queryParams.orderBy;
      delete queryParams.orderDirection;
    }

    if (!params.sspId) {
      delete queryParams.sspId;
    }

    if (!params.dspId) {
      delete queryParams.dspId;
    }

    if (!params.companyId) {
      delete queryParams.companyId;
    }

    if (!params.limit) {
      delete queryParams.limit;
    }

    if (!params.offset) {
      delete queryParams.offset;
    }

    return `${endpoints.statistics.auctionStatisticsByTable(params.table)}${params.export ? '/export' : ''}?${querystring.stringify(queryParams)}`;
  };

  const listStatisticsByTable = (params: ListStatisticsParams) => {
    return authorizedGET<ListStatisticsByTableResponse>(getListStatisticsByTableUrl(params));
  };

  return {
    listTables,
    listStatisticsByTable,
    getListStatisticsByTableUrl,
  };
}
