import querystring from 'querystring';

import { ssp, statistics } from '../constants/endpoints';

export default ({ authorizedGET, authorizedPOST, authorizedPUT, authorizedDELETE }, endpoints) => {
  // it will fetch all existing SSPs from API
  // note that it's using hardcoded limit and it's mean that at one point
  // of time it won't return ALL dsps
  // TODO: remove hardcoded data fetching
  const getAll = async (qp = {}) => {
    const queryParams = {
      limit: 5000,
      offset: 0,
      ...qp,
    };

    return authorizedGET(`${endpoints.ssp.list}?${querystring.stringify(queryParams)}`);
  };

  const getById = async (id) => authorizedGET(`${endpoints.ssp.list}/${id}`);

  const createSsp = (obj) => authorizedPOST(ssp.list, obj);

  const updateSsp = (id, obj) => authorizedPUT(`${endpoints.ssp.list}/${id}`, obj);

  const getSspsHourlyStatistics = (queryParams = {}) => {
    const url = statistics.hourly.ssp();
    return authorizedGET(`${url}?${querystring.stringify(queryParams)}`);
  };

  const getSspCountriesWhiteList = async (sspId) => {
    try {
      // eslint-disable-next-line @typescript-eslint/return-await
      return await authorizedGET(
        `${endpoints.list.fetchSspWlCountries.replace(':sspId', sspId)}?${querystring.stringify({
          limit: 5000,
          offset: 0,
        })}`
      );
    } catch (error) {
      return [];
    }
  };

  const getSspDspWhiteList = async (sspId) => {
    try {
      // eslint-disable-next-line @typescript-eslint/return-await
      return await authorizedGET(
        `${endpoints.list.fetchWlDsps.replace(':sspId', sspId)}?${querystring.stringify({
          limit: 5000,
          offset: 0,
        })}`
      );
    } catch (error) {
      return [];
    }
  };

  const createIabCategories = (sspId, data) => authorizedPOST(`${endpoints.sspIabCategories}/${sspId}`, data);

  const getIabCategories = (sspId) => authorizedGET(`${endpoints.sspIabCategories}/${sspId}`);

  const getSellerInfo = (sspId) => {
    const url = endpoints.sspSellerInfo.replace(':id', sspId);
    return authorizedGET(url);
  };

  const updateSellerInfo = (sspId, body) => {
    const url = endpoints.sspSellerInfo.replace(':id', sspId);
    return authorizedPUT(url, body);
  };

  const createSellerInfo = (sspId, body) => {
    const url = endpoints.sspSellerInfo.replace(':id', sspId);
    return authorizedPOST(url, body);
  };

  const deleteSellerInfo = (sspId) => {
    const url = endpoints.sspSellerInfo.replace(':id', sspId);
    return authorizedDELETE(url);
  };

  return {
    getAll,
    createSsp,
    updateSsp,
    getSspsHourlyStatistics,
    getSspDspWhiteList,
    createIabCategories,
    getIabCategories,
    getSellerInfo,
    updateSellerInfo,
    createSellerInfo,
    deleteSellerInfo,
    getById,
    getSspCountriesWhiteList,
  };
};
