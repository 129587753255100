/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Select as AntSelect, Typography as AntTypography, Card, Table, Button, notification } from 'antd';
import { Select } from '../index';
import { authorizedPOST } from '../../helpers/requester';
import apiGatewayFactory from '../../apiGateway';
import getOr from '../../helpers/getOr';
import globalListCategoriesJson from '../../constants/globalListCategories.json';
import { PredefinedValues } from './components/PredefinedValues';
import ListDragAndDrop from '../ListDragAndDrop';

const { Option, OptGroup } = AntSelect;

export default function WhiteOrBlackList(props) {
  const apiGateway = apiGatewayFactory();

  const {
    title,
    urlTemplate,
    postUrl,
    id,
    keyName,
    primaryKeyName,
    parseValFunc,
    onEnd,
    onDelete,
    allowedValues,
    listName,
    hiddenColumns = [],
  } = props;

  const [state, setState] = useState({
    list: '',
    localList: [],
  });

  const [aVals, setAVals] = useState([]);
  const [globalLists, setGlobalLists] = useState(null);
  const [selectedGlobalLists, setSelectedGlobalLists] = useState(null);
  const [selectedGlobalListsOriginal, setSelectedGlobalListsOriginal] = useState(null);
  const [localList, setLocalList] = useState([]);

  const getData = async () => {
    if (id) {
      try {
        if (!allowedValues) {
          const localListUrl = `${urlTemplate.replace(`:${primaryKeyName}`, id)}?limit=10000&offset=0`;
          const localListResponse = await apiGateway.authorizedGET(localListUrl);

          setState({ ...state, localList: localListResponse });
        }

        const globalListsResponse = await apiGateway.globalLists.getGlobalLists();

        setGlobalLists(getOr(globalListsResponse, 'data', null));

        if (listName) {
          const selectedGlobalListsResponse = await apiGateway.globalLists.getGlobalListCommonLists({
            dspOrSspId: id,
            isDsp: 1,
            listName,
          });

          setSelectedGlobalLists(getOr(selectedGlobalListsResponse, 'data', []).map((x) => x.globalListId));
          setSelectedGlobalListsOriginal(getOr(selectedGlobalListsResponse, 'data', []));
        }
      } catch (error) {
        // Do nothing
      }
    }
  };

  const addSelectedGlobalLists = async () => {
    if (!id) {
      notification.info({ message: 'Please, fetch DSP and then try again' });
      return;
    }

    const dataToDelete = (selectedGlobalListsOriginal || []).filter(
      (x) => !(selectedGlobalLists || []).includes(x.globalListId)
    );
    const dataToAdd = (selectedGlobalLists || []).filter(
      (x) => !(selectedGlobalListsOriginal || []).some((y) => y.globalListId === x)
    );

    try {
      if (dataToDelete.length || dataToAdd.length) {
        await apiGateway.globalLists.addGlobalListsToCommonList(listName, id, 1, dataToAdd);
        await apiGateway.globalLists.deleteGlobalListsCommonLists(dataToDelete.map((x) => x.id));

        notification.success({ message: 'Global Lists updated' });
      }
    } catch (e) {
      notification.error({ message: `Error when adding Global Lists: ${e}` });
    } finally {
      await getData();
    }
  };

  useEffect(() => {
    setAVals(allowedValues);

    (async () => {
      await getData();
    })();
  }, _.values(props));

  const submit = async () => {
    await addSelectedGlobalLists();

    if (!id) {
      notification.error({
        message: 'Please, fetch DSP and then try again',
      });

      return;
    }

    try {
      if (localList.length) {
        const data = localList
          .filter((x) => !state.localList.some((y) => y[keyName] === x))
          .map((item) => ({
            [primaryKeyName]: id,
            [keyName]: parseValFunc ? parseValFunc(item) : item,
          }));

        if (!data.length) {
          notification.info({
            message: 'All items have been filtered out as they were detected to be duplicates',
          });

          return;
        }

        if (localList.length !== data.length) {
          notification.info({
            message: `${
              localList.length - data.length
            } items have been filtered out as they were detected to be duplicates`,
          });
        }

        await authorizedPOST(postUrl, data);

        setState({ ...state, list: [] });

        notification.success({
          message: `${data.length} items have been added`,
        });
      }
    } catch (e) {
      notification.error({
        message: e.toString(),
      });
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onEnd && onEnd();
    }
  };

  const submitToggled = async () => {
    if (!id) {
      notification.info({ message: 'Please, fetch DSP and then try again' });
      return;
    }

    await addSelectedGlobalLists();

    try {
      if (aVals.length > 0) {
        const newAVals = aVals.filter((x) => !allowedValues.find((y) => _.isEqual(x, y)));
        const itemsToCreate = newAVals.filter((x) => x.__toggle);
        const itemsToDelete = newAVals.filter((x) => !x.__toggle);

        if (itemsToCreate.length) {
          await authorizedPOST(
            postUrl,
            itemsToCreate.map((item) => ({
              [primaryKeyName]: id,
              [keyName]: parseValFunc ? parseValFunc(item[keyName]) : item[keyName],
            }))
          );

          notification.success({ message: `${itemsToCreate.length} items created` });
        }

        if (itemsToDelete.length) {
          for (const val of itemsToDelete) {
            await onDelete(null, val);
          }

          notification.success({ message: `${itemsToDelete.length} items deleted` });
        }

        setState({ ...state, list: [] });
      }
    } catch (e) {
      notification.error({ message: `Error: ${e}` });
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onEnd && onEnd();
    }
  };

  const onToggle = (data) => {
    setAVals(
      (aVals || []).map((x) => {
        if (x[keyName] === data[keyName]) {
          return { ...x, __toggle: !x.__toggle };
        }

        return x;
      })
    );
  };

  const globalListSelectProps = {
    bordered: true,
    clearIcon: true,
    mode: 'multiple',
    placeholder: 'Select Global Lists',
    value: selectedGlobalLists || [],
    onSelect: (value) => {
      setSelectedGlobalLists(_.uniq([...(selectedGlobalLists || []), value]));
    },
    onDeselect: (value) => {
      setSelectedGlobalLists((selectedGlobalLists || []).filter((x) => x !== value));
    },
    style: { width: 300, color: 'black', marginBottom: 20 },
  };

  return (
    <div>
      <div className="col-md-12">
        <Card title={title}>
          {listName && (
            <>
              <Select {...globalListSelectProps}>
                {_.isArray(globalLists) &&
                  globalListCategoriesJson.map((x) => (
                    <OptGroup key={x.label} label={x.label}>
                      {globalLists
                        .filter((list) => list.category === x.value)
                        .map((list) => (
                          <Option key={list.id} value={list.id}>
                            {list.title}
                          </Option>
                        ))}
                    </OptGroup>
                  ))}
              </Select>
              {_.isArray(globalLists) && (
                <AntTypography.Text strong>and/or insert the custom list here</AntTypography.Text>
              )}
            </>
          )}

          {(state.localList.length && (
            <Table
              pagination={false}
              dataSource={state.localList.map((x, index) => ({ ...x, index }))}
              columns={[
                {
                  title: '#',
                  dataIndex: 'index',
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                },
                {
                  title: keyName,
                  dataIndex: keyName,
                },
                {
                  title: '',
                  dataIndex: '',
                  render: (skip, record) => (
                    <Button type="text" danger onClick={() => onDelete(null, record)}>
                      Delete
                    </Button>
                  ),
                },
              ]}
              scroll={{ y: 300 }}
            />
          )) ||
            ''}

          {allowedValues ? (
            <PredefinedValues
              aVals={aVals}
              onToggle={onToggle}
              keyName={keyName}
              hiddenColumns={hiddenColumns}
              submitToggled={submitToggled}
            />
          ) : (
            <>
              <ListDragAndDrop onOk={setLocalList} />
              <br />
              <Button type="primary" onClick={submit}>
                Save
              </Button>
            </>
          )}
        </Card>
      </div>
    </div>
  );
}
