import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Row, Col, Skeleton, Collapse, notification } from 'antd';
import { useParams } from 'react-router-dom';

import { WhiteOrBlackList } from '../../../components';
import getRandomString from '../../../helpers/getRandomString';
import getOr from '../../../helpers/getOr';
import boolToSmallInt from '../../../helpers/boolToSmallInt';
import { createApiGateway } from '../../../apiGateway';
import validations from '../ssps/utils/validations';
import alpha3CountryCodeList from '../../../constants/alpha3CountryCodeList.json';
import { list } from '../../../constants/endpoints';
import { authorizedDELETE } from '../../../helpers/requester';
import SellerInfo from '../ssps/SellerInfo';
import UpsertSspForm from '../../../sections/ssps/UpsertSspForm';
import { UpsertSspFormBidRequestSwapper } from '../../../sections/ssps/UpsertSspFormBidRequestSwapper';

const { Panel } = Collapse;

export default function SspPage() {
  const params = useParams();

  const sspId = params.id;
  const isCreateMode = sspId === 'new';

  const apiGateway = createApiGateway();

  const [existingSsp, setExistingSsp] = useState();
  const [loading, setLoading] = useState(true);
  const [dsps, setDsps] = useState([]);
  const [ssps, setSsps] = useState([]);
  const [sspDspWhiteList, setSspDspWhiteList] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [iabCategories, setIabCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [whiteLists, setWhiteLists] = useState({});

  const onUpsertSsp = () => {
    setTimeout(() => {
      window.location = '/ssps';
    }, 1000 * 2);
  };

  useEffect(() => {
    (async () => {
      const usersResponse = await apiGateway.users.getAll();
      setUsers(usersResponse.filter((x) => x.role === 'user'));

      if (isCreateMode) {
        setLoading(false);

        return;
      }

      const ssp = await apiGateway.ssps.getById(sspId);

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const [dsps, ssps, sspDspWhiteList, countriesWhiteList] = await Promise.all([
        apiGateway.dsps.getAll(),
        apiGateway.ssps.getAll(),
        apiGateway.ssps.getSspDspWhiteList(sspId),
        apiGateway.ssps.getSspCountriesWhiteList(sspId),
      ]);

      if (_.isString(ssp.catsForHB) && _.size(ssp.catsForHB)) {
        setIabCategories(ssp.catsForHB.split(','));
      } else {
        setIabCategories([]);
      }

      setWhiteLists({
        ...whiteLists,
        countriesWhiteList,
      });

      setExistingSsp(ssp);
      setDsps(dsps);
      setSsps(ssps);
      setSspDspWhiteList(sspDspWhiteList);

      setLoading(false);
    })();
  }, []);

  const updateDsp = async (formObj) => {
    try {
      const ssp = boolToSmallInt({
        ...formObj,
        authName: existingSsp.authName,
        authPassword: existingSsp.authPassword,
      });

      validations.validateSsp(ssp);

      await apiGateway.ssps.updateSsp(existingSsp.id, ssp);

      notification.success({ message: 'SSP updated' });

      onUpsertSsp();
    } catch (e) {
      notification.error({ message: `Error when updating an SSP: ${e}` });
    }
  };

  const createSsp = async (formObj) => {
    const ssp = boolToSmallInt({
      ...formObj,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      authName: getAuthName(getOr(formObj, 'name', '')),
      authPassword: getRandomString({ useMd5: true }),
    });

    try {
      validations.validateSsp(ssp);

      await apiGateway.ssps.createSsp(ssp);

      notification.success({ message: 'SSP created' });

      onUpsertSsp();
    } catch (e) {
      notification.error({ message: `Error when creating an SSP: ${e}` });
    }
  };

  const onFormFinish = (values) => {
    const dataToSave = _.omitBy(
      {
        ...(existingSsp || {}),
        ...values,
        catsForHB: values.iabCategories ? values.iabCategories.join(',') : null,
      },
      _.isNil
    );

    return (isCreateMode ? createSsp : updateDsp)(dataToSave);
  };

  const getAuthName = (name) =>
    name
      .toLowerCase()
      .split('')
      .filter((c) => /\w/.test(c))
      .join('');

  const onEnd = () => {};

  // TODO: Move to apiGateway
  const onDelete = (baseUrl) => async (e, data) => {
    try {
      await authorizedDELETE(baseUrl.replace(':id', data.id));
      notification.success({ message: 'Deleted' });
    } catch (error) {
      notification.error({ message: `Oops.. sth went wrong: ${error}` });
    }
  };

  const allowedWhiteListDspValues = dsps
    .map((x) => ({ dspId: x.id, name: x.name, active: x.isActive ? '+' : '-' }))
    .map((x) => {
      const sameFromApi = sspDspWhiteList.find((y) => y.dspId === x.dspId);

      return {
        __toggle: Boolean(sameFromApi),
        ...x,
        id: (sameFromApi && sameFromApi.id) || null,
      };
    });

  const allowedWhiteListCountryValues = alpha3CountryCodeList
    .map((x) => ({
      country: x.alpha3,
      name: x.country,
    }))
    .map((x) => {
      const sameFromApi = (whiteLists.countriesWhiteList || []).find((y) => y.country === x.country);

      return {
        __toggle: Boolean(sameFromApi),
        ...x,
        id: (sameFromApi && sameFromApi.id) || null,
      };
    });

  if (loading) {
    return (
      <Row>
        <Col offset={2} span={20}>
          <Skeleton active />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col offset={2} span={20}>
        <Collapse bordered={false} defaultActiveKey={['1']} style={{ backgroundColor: 'transparent' }}>
          {existingSsp && (
            <Panel header="Information" key="0">
              <ul>
                <li>
                  <b>Hourly Report:</b> {existingSsp.statUrl || 'n/a'}
                </li>
                <li>
                  <b>Endpoint US East (New York):</b>{' '}
                  {`http://useast.justbidit.xyz:8800/?name=${existingSsp.authName}&pass=${existingSsp.authPassword}`}
                </li>
                <li>
                  <b>Endpoint Asia (Singapore):</b>{' '}
                  {`http://sg.justbidit.xyz:8800/?name=${existingSsp.authName}&pass=${existingSsp.authPassword}`}
                </li>
                <li>
                  <b>Endpoint Japan:</b>{' '}
                  {`http://jp.justbidit.xyz:8800/?name=${existingSsp.authName}&pass=${existingSsp.authPassword}`}
                </li>
              </ul>
            </Panel>
          )}

          <Panel header="General" key="1">
            <UpsertSspForm ssp={existingSsp} onFinish={onFormFinish} users={users} />
          </Panel>

          <Panel header="Bid Request Swapper" key="3">
            <UpsertSspFormBidRequestSwapper ssp={existingSsp} ssps={ssps} onFinish={onFormFinish} />
          </Panel>

          {existingSsp && (
            <Panel header="White and Black lists" key="4">
              <>
                <WhiteOrBlackList
                  title="Add DSP to WL"
                  urlTemplate={list.fetchWlDsps}
                  primaryKeyName="sspId"
                  keyName="dspId"
                  parseValFunc={parseInt}
                  postUrl={list.wlDspsBulk}
                  onEnd={onEnd}
                  onDelete={onDelete(list.deleteWlDsp)}
                  allowedValues={allowedWhiteListDspValues}
                  hiddenColumns={['id']}
                  id={existingSsp.id}
                />

                <WhiteOrBlackList
                  title="Add Crid to BL"
                  urlTemplate={list.fetchBlCrids}
                  primaryKeyName="sspId"
                  keyName="crid"
                  postUrl={list.blCridsBulk}
                  onEnd={onEnd}
                  onDelete={onDelete(list.deleteBlCrids)}
                  hiddenColumns={['id']}
                  id={existingSsp.id}
                />

                <WhiteOrBlackList
                  title="Add Adomain to BL"
                  urlTemplate={list.fetchBlAdomains}
                  primaryKeyName="sspId"
                  keyName="domain"
                  postUrl={list.blAdomainsBulk}
                  onEnd={onEnd}
                  onDelete={onDelete(list.deleteBlAdomains)}
                  hiddenColumns={['id']}
                  id={existingSsp.id}
                />

                <WhiteOrBlackList
                  title="Add Countries to WL"
                  urlTemplate={list.fetchSspWlCountries}
                  id={existingSsp.id}
                  primaryKeyName="sspId"
                  keyName="country"
                  postUrl={list.sspWlCountriesBulk}
                  onDelete={onDelete(list.deleteSspWlCountries)}
                  allowedValues={allowedWhiteListCountryValues}
                  hiddenColumns={['id']}
                />

                {existingSsp && existingSsp.id && (
                  <div>
                    <br />
                    <br />
                    <SellerInfo sspId={existingSsp.id} />
                  </div>
                )}
              </>
            </Panel>
          )}
        </Collapse>
      </Col>
    </Row>
  );
}
