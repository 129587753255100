import { API_URL, EXCHANGE_NODE_BASE_URL } from './application';

const wrapWithAPIUrl = (object) => {
  const temp = {};
  Object.entries(object).forEach(([key, value]) => {
    temp[key] = `${API_URL}${value}`;
  });
  return temp;
};

export const user = wrapWithAPIUrl({
  login: '/login',
  root: '/user',
  me: '/user/me',
  access: '/users/access',
  users: '/users',
});

export const ssp = wrapWithAPIUrl({
  list: '/ssp',
});

export const dsp = wrapWithAPIUrl({
  list: '/dsp',
});

export const system = wrapWithAPIUrl({
  status: '/system/status',
  changeMode: '/system/change-mode',
});

export const samples = wrapWithAPIUrl({
  list: '/samples',
  samplesRequest: '/samples/request',
});

export const list = wrapWithAPIUrl({
  wlCountries: '/list/white/dsp/country',
  wlSizes: '/list/white/dsp/sizes',
  wlSsps: '/list/white/dsps',
  wlBundles: '/list/white/dsp/bundles',
  wlOs: '/list/white/dsp/os',

  wlDspsBulk: '/list/white/ssps/bulk',
  wlSspsBulk: '/list/white/dsps/bulk',
  wlSizesBulk: '/list/white/dsp/sizes/bulk',
  wlBundlesBulk: '/list/white/dsp/bundles/bulk',
  wlCountriesBulk: '/list/white/dsp/country/bulk',
  sspWlCountriesBulk: '/list/white/ssp/country/bulk',
  wlOsBulk: '/list/white/dsp/os/bulk',
  wlDomainsBulk: '/list/white/dsp/domains/bulk',

  fetchWlDsps: '/list/white/ssps/ssp/:sspId',
  fetchWlSsps: '/list/white/dsps/dsp/:dspId',
  fetchWlSizes: '/list/white/dsp/sizes/dsp/:dspId',
  fetchWlBundles: '/list/white/dsp/bundles/dsp/:dspId',
  fetchWlCountries: '/list/white/dsp/country/dsp/:dspId',
  fetchSspWlCountries: '/list/white/ssp/country/ssp/:sspId',
  fetchWlOs: '/list/white/dsp/os/dsp/:dspId',
  fetchWlDomains: '/list/white/dsp/domains/dsp/:dspId',

  blBundlesBulk: '/list/black/dsp/bundles/bulk',
  blDomainsBulk: '/list/black/dsp/domains/bulk',
  blPublishersBulk: '/list/black/dsp/publishers/bulk',
  blCridsBulk: '/list/black/ssp/crids/bulk',
  blAdomainsBulk: '/list/black/ssp/adomains/bulk',
  blIPsBulk: '/list/black/ips/bulk',

  fetchBlBundles: '/list/black/dsp/bundles/dsp/:dspId',
  fetchBlDomains: '/list/black/dsp/domains/dsp/:dspId',
  fetchBlPublishers: '/list/black/dsp/publishers/dsp/:dspId',
  fetchBlCrids: '/list/black/ssp/crids/ssp/:sspId',
  fetchBlAdomains: '/list/black/ssp/adomains/ssp/:sspId',
  fetchBlIPs: '/list/black/ips/dsp/:dspId',

  deleteWlDsp: '/list/white/ssps/row/:id',
  deleteWlSsp: '/list/white/dsps/row/:id',
  deleteWlSizes: '/list/white/dsp/sizes/row/:id',
  deleteWlBundles: '/list/white/dsp/bundles/row/:id',
  deleteWlCountries: '/list/white/dsp/country/row/:id',
  deleteSspWlCountries: '/list/white/ssp/country/row/:id',
  deleteWlOs: '/list/white/dsp/os/row/:id',
  deleteWlDomains: '/list/white/dsp/domains/row/:id',

  deleteBlBundles: '/list/black/dsp/bundles/row/:id',
  deleteBlDomains: '/list/black/dsp/domains/row/:id',
  deleteBlPublishers: '/list/black/dsp/publishers/row/:id',
  deleteBlCrids: '/list/black/ssp/crids/row/:id',
  deleteBlAdomains: '/list/black/ssp/adomains/row/:id',
  deleteBlIPs: '/list/black/ips/:id',
});

export const statistics = {
  hourly: {
    dsp: (id = null) => `${API_URL}/statistics/hourly/dsp${id ? `/${id}` : ''}`,
    ssp: (id = null) => `${API_URL}/statistics/hourly/ssp${id ? `/${id}` : ''}`,
    common: {
      ssp: (id = null) => `${API_URL}/statistics/hourly/common/ssp${id ? `/${id}` : ''}`,
      dsp: (id = null) => `${API_URL}/statistics/hourly/common/dsp${id ? `/${id}` : ''}`,
      dspV1: (id = null) => `[v1]${API_URL}/statistics/hourly/common/dsp${id ? `/${id}` : ''}`,
      sspV1: (id = null) => `[v1]${API_URL}/statistics/hourly/common/ssp${id ? `/${id}` : ''}`,
      partner: (id) => `${API_URL}/statistics/hourly/common/partner/${id || ''}`,
    },
    total: `${API_URL}/statistics/hourly/common/total`,
    totalEndpoint: `${API_URL}/statistics/hourly/common/total/endpoint`,
  },
  eventLogs: () => `${API_URL}/eventLogs`,
  qps: `${API_URL}/statistics/qps`,
  tables: `${API_URL}/auction-statistics/tables`,
  auctionStatisticsByTable: (table) => `${API_URL}/auction-statistics/tables/${table}`,
};

const globalLists = {
  root: `${API_URL}/globalLists`,
  categories: `${API_URL}/globalLists/categories`,
};

export default {
  user,
  ssp,
  dsp,
  samples,
  system,
  statistics,
  list,
  sspIabCategories: `${API_URL}/ssps/iabCategories`,
  sspSellerInfo: `${API_URL}/ssp/:id/sellerInfo`,
  globalLists,
  lists: `${API_URL}/lists`,
  exchangeNodeBaseUrl: EXCHANGE_NODE_BASE_URL,
  asianExchangeBaseUrl: 'http://sg.justbidit.xyz:8800',
};
